
//import '../../../../bootstrap-vue-app/src/pages/wxwidgets/wxwidgets';
//import '/../bootstrap-vue-app/src/pages/wxwidgets/wxwidgets';
import './index.scss'


import "/src/main.js"
import "/src/accountcomponents.js"



(function() {
  
    function bindWxForm() {
        var wxdataform=document.getElementById("wxdataform");
        if (!wxdataform) return;
        wxdataform.addEventListener("submit", function(event) {
            event.preventDefault();
            var location=document.getElementById('wxlocation').value;
            var periodtype=document.getElementById('periodtype').value;


            location=location && encodeURIComponent(location);
            periodtype=periodtype && periodtype.toLowerCase();

           
            if ("forecast"===periodtype) {
                window.location="/weather-forecast/"+location;
            } else  if ("history"===periodtype) {
                window.location="/weather-history/"+location;
            } 
        });
    }

    window.addEventListener("load",function(event) {
        bindWxForm();
    },false);

})();
